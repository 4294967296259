import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AnimatedHr from '../components/animatedHr'

import serviceOne from '../images/PCB-repairs.png'
import serviceTwo from '../images/catering-equipment-repairs.png'
import serviceThree from '../images/industrial-equipment-repair.png'
import serviceFour from '../images/ferric-meters.png'
import serviceFive from '../images/other-services.png'

const Services = () => (
  <Layout>
    <div className="headerImage service">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>Services</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>
              Your Sheffield Experts in repairing electronic devices from
              catering equipment to laptops.
            </p>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="container">
        <div className="row">
          <div className="columns four">
            <Link to="/pcb-repairs">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceOne + ')' }}
                />
                <p>
                  <br /> PCB Repairs
                </p>
              </div>
            </Link>
          </div>
          <div className="columns four">
            <Link to="/catering-repairs">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceTwo + ')' }}
                />

                <p>
                  <br /> Catering Repairs
                </p>
              </div>
            </Link>
          </div>
          <div className="columns four">
            <Link to="/industrial-repairs">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceThree + ')' }}
                />
                <p>
                  <br /> Industrial Repairs
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="columns one">
            <p />
          </div>
          <div className="columns five">
            <Link to="/about-us">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceFour + ')' }}
                />
                <p>
                  <br /> About Us
                </p>
              </div>
            </Link>
          </div>
          <div className="columns five">
            <Link to="/ferric-meter">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceFive + ')' }}
                />
                <p>
                  <br /> Ferric Meters
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Services
